import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from "react-modal";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import './index.css';
import Call from '@mui/icons-material/Call';
import LocationPin from '@mui/icons-material/LocationOn';
import Mail from '@mui/icons-material/Mail';
import Facebook from '@mui/icons-material/Facebook';
import Copyright from '@mui/icons-material/Copyright';

import samuraiImage from './img/samuraiCert_128.png';
import armstrongBanner from './img/ArmstrongCARlogo_FieldBanner_600.png';

import axios from "axios";

const rootElement = document.getElementById("root");
Modal.setAppElement(rootElement);

const NavButton = styled(Button)(() => ({
    color: '#ffffff',
    fontSize: '16px',
    fontFamily: 'Mukta'
}));

const CallIcon = styled(Call)(() => ({
    verticalAlign: 'middle'
}));

// const FacebookIcon = styled(Facebook)(() => ({
//     verticalAlign: 'middle'
// }));

const MailIcon = styled(Mail)(() => ({
    height: '20px',
    verticalAlign: 'middle'
}));

const CopyIcon = styled(Copyright)(() => ({
    height: '20px',
    verticalAlign: 'middle'
}));

const LocationPinIcon = styled(LocationPin)(() => ({
    height: '20px',
    verticalAlign: 'middle',
    color: '#C1272D'
}));

const RedMailIcon = styled(Mail)(() => ({
    height: '20px',
    verticalAlign: 'middle',
    color: '#C1272D'
}));

const RedCallIcon = styled(Call)(() => ({
    height: '20px',
    verticalAlign: 'middle',
    color: '#C1272D'
}));

const RedFacebookIcon = styled(Facebook)(() => ({
    height: '20px',
    verticalAlign: 'middle',
    color: '#C1272D'
}));

const SubmitButton = styled(Button)(() => ({
    color: '#ffffff',
    backgroundColor: '#C1272D',
    fontSize: '12px',
    marginBottom: '20px',
    '&:hover': {
        backgroundColor: '#9F2025'
    }
}));

const CloseButton = styled(Button)(() => ({
    color: '#ffffff',
    backgroundColor: '#C1272D',
    fontSize: '12px',
    marginTop: '20px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#9F2025'
    }
}));

// const HourBox = styled(Grid)(() => ({
//     backgroundColor: '#2E3192;',
//     color: '#ffffff',
//     borderColor: '#fff',
//     borderStyle: 'solid',
//     margin: 'auto',
//     paddingRight: '10px',
//     paddingLeft: '10px'
// }));

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            cityState: '',
            contactNumber: '',
            modelNumber: '',
            description: '',
            appType: '',
            showModal: false,
            showErrorModal: false
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)

        this.handleOpenErrorModal = this.handleOpenErrorModal.bind(this)
        this.handleCloseErrorModal = this.handleCloseErrorModal.bind(this)
    }


    handleSubmit(event) {



        const { firstName, lastName, email, cityState, contactNumber, modelNumber, description, appType } = this.state;
        const submittedContact = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            cityState: cityState,
            contactNumber: contactNumber,
            modelNumber: modelNumber,
            description: description,
            appType: appType
        }
        event.preventDefault();

        if ((firstName && firstName !== "") && (contactNumber && contactNumber !== "")) {
            axios.put('https://armstrongcertified.com/contact', { submittedContact })
                .then(res => {
                    console.log(res);
                    console.log(res.data);
                    this.handleOpenModal();
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else {
                        console.log('Error', error.message);
                    }
                    this.handleOpenErrorModal();
                })
        } else {
            //TODO - Add Validation Message
        }

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleCloseModal() {
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            cityState: '',
            contactNumber: '',
            modelNumber: '',
            description: '',
            appType: '',
            showModal: false
        })
    }

    handleOpenModal() {
        this.setState({
            showModal: true
        })
    }

    handleCloseErrorModal() {
        this.setState({
            showErrorModal: false
        })
    }

    handleOpenErrorModal() {
        this.setState({
            showErrorModal: true
        })
    }


    render() {

        return (
            <Grid container className="main">
                <Grid xs={12} lg={8} xsOffset={0} lgOffset={2} className="back-pic">
                    <Grid sx={{ display: { xs: 'none', sm: 'block' } }} className="top-gap"></Grid>

                    <Grid container>
                        <Grid container height={"100px"} xs={12} className="title-box-logo" justifyContent="center" direction="column">
                            <Grid xs={10} xsOffset={1} sm={8} smOffset={2} className="title-box-logo-img"></Grid>
                        </Grid>

                        <Grid xs={12} sx={{ height: { xs: '120px', sm: '80px' }, fontSize: { xs: '18px', sm: '20px' } }} container className="tagline-box">
                            <Grid sx={{ display: { xs: 'block', sm: 'none' } }} xs={12} className="tagline-text">When an appliance starts having problems, it can be pretty frustrating. If you are in need of appliance repair, then look no further!</Grid>
                            <Grid sx={{ display: { xs: 'none', sm: 'block' } }} xs={12} className="tagline-text">When an appliance starts having problems, it can be pretty frustrating.<br ></br>If you are in need of appliance repair, then look no further!</Grid>
                        </Grid>

                        {/* XS Title Link Bar */}
                        <Grid xs={12} className="title-box-links" sx={{ display: { xs: 'block', md: 'none' } }}>
                            <Stack spacing={0} marginLeft={'50px'} >
                                <Grid height={"67px"} container alignContent="center" justifyContent="center" direction="column" className="title-contact-element">
                                    <Grid xs={12} fontSize={'20px'}>
                                        <CallIcon height={'20px'}></CallIcon>&nbsp;&nbsp;575-284-9771
                                    </Grid>
                                </Grid>

                                <Grid height={"67px"} container alignContent="center" justifyContent="center" direction="column" className="title-contact-element">
                                    <Grid xs={12} fontSize={'20px'}>
                                        <MailIcon height={'20px'}></MailIcon>&nbsp;&nbsp;armstrong.certified@gmail.com
                                    </Grid>
                                </Grid>

                            </Stack>
                        </Grid>

                        {/* MD and Up Title Link Bar */}
                        <Grid xs={12} className="title-box-links" height={'67px'} sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Stack direction="row" spacing={4} marginLeft={'100px'} >
                                <Grid height={"67px"} container alignContent="center" justifyContent="center" direction="column" className="title-contact-element">
                                    <Grid xs={12} fontSize={'16px'}>
                                        <CallIcon sx={{ height: { xs: "20px", sm: "20px" } }}></CallIcon>&nbsp;&nbsp;575-284-9771
                                    </Grid>
                                </Grid>

                                <Grid height={"67px"} container alignContent="center" justifyContent="center" direction="column" className="title-contact-element">
                                    <Grid xs={12} fontSize={'16px'}>
                                        <MailIcon sx={{ height: { xs: "20px", sm: "20px" } }}></MailIcon>&nbsp;&nbsp;armstrong.certified@gmail.com
                                    </Grid>
                                </Grid>

                                <Grid justifyContent="center" textAlign="center" direction="column" xs={2} className="title-contact-element">

                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>

                    <div className="nav-bar">
                        <Stack sx={{ display: { xs: "block", md: "none" } }} spacing={0} direction="row">
                            <NavButton variant="text">Home</NavButton>
                            {/* <NavButton disabled variant="text">About</NavButton>
                            <NavButton disabled variant="text">Services</NavButton>
                            <NavButton disabled variant="text">Appliance Parts</NavButton> */}
                        </Stack>
                        <Stack sx={{ display: { xs: "none", md: "block" } }} className="nav-content" spacing={4} direction="row">
                            <NavButton variant="text">Home</NavButton>
                            {/* <NavButton disabled variant="text">About</NavButton>
                            <NavButton disabled variant="text">Services</NavButton>
                            <NavButton disabled variant="text">Appliance Parts</NavButton> */}
                        </Stack>
                    </div>

                    <Grid sx={{ height: { xs: "60px", md: "80px" }, fontSize: { xs: "20px", md: "45px" } }} className="serving-banner">
                        Serving all of Grant County, New Mexico
                    </Grid>

                    <Grid sx={{ height: { xs: "60px", md: "80px" }, fontSize: { xs: "20px", md: "40px" } }} className="serving-banner">
                        Weekend Appointments Available
                    </Grid>

                    <Grid xs={12} container>

                        <Grid xs={12} xsOffset={0} md={6} mdOffset={1} className="con-form">
                            <div className="con-form-title">
                                Contact Us Today
                            </div>
                            <Grid container>
                                <Grid xs={10} xsOffset={1} >
                                    <Stack className="form-content" spacing={1} direction="column">
                                        <TextField required name="firstName" label="First Name" variant="outlined" value={this.state.firstName} onChange={this.handleChange} />
                                        <TextField name="lastName" label="Last Name" variant="outlined" value={this.state.lastName} onChange={this.handleChange} />
                                        <TextField required name="contactNumber" label="Contact Number" variant="outlined" value={this.state.contactNumber} onChange={this.handleChange} />
                                        <TextField name="email" label="Email Address" variant="outlined" value={this.state.email} onChange={this.handleChange} />
                                        <TextField name="cityState" label="City, State" variant="outlined" value={this.state.cityState} onChange={this.handleChange} />
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                                            <InputLabel id="app-type-label">Type of Appliance</InputLabel>
                                            <Select
                                                name="appType"
                                                labelId="app-type-label"
                                                id="app-type"
                                                value={this.state.appType}
                                                label="Type of Appliance"
                                                onChange={this.handleChange}
                                            >
                                                <MenuItem value={'Washer'}>Washer</MenuItem>
                                                <MenuItem value={'Dryer'}>Dryer</MenuItem>
                                                <MenuItem value={'Refrigerator'}>Refrigerator</MenuItem>
                                                <MenuItem value={'Dishwasher'}>Dishwasher</MenuItem>
                                                <MenuItem value={'Microwave'}>Microwave</MenuItem>
                                                <MenuItem value={'Oven/Range'}>Oven/Range</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField name="modelNumber" label="Model Number" variant="outlined" value={this.state.modelNumber} onChange={this.handleChange} />
                                        <TextField name="description" multiline minRows={4} maxRows={4} className="description-field" label="Brief Description of Your Issue" variant="outlined" value={this.state.description} onChange={this.handleChange} />
                                    </Stack>
                                </Grid>

                                <Grid xs={6} xsOffset={6}>
                                    <Box textAlign='center'>
                                        <SubmitButton variant="contained" onClick={this.handleSubmit}>Submit</SubmitButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>


                    <Grid sx={{ display: { xs: "block", md: "none" } }} fontSize={"35px"} className="serving-banner">
                        Or Call Us Today At<br></br>575-284-9771
                    </Grid>
                    <Grid sx={{ display: { xs: "none", md: "block" } }} fontSize={"45px"} className="serving-banner">
                        Or Call Us Today At 575-284-9771
                    </Grid>

                </Grid >

                <Grid xs={12} lg={8} mdOffset={0} lgOffset={2} container className="footer">
                    <Grid xs={10} xsOffset={1} md={6} mdOffset={0} lg={6} lgOffset={1} xl={4} >
                        <Stack className="footer-box" spacing={0}>
                            {/* <Box className="footer-box-logo-img"></Box> */}
                            <Box>&nbsp;</Box>
                            <img border={0} src={armstrongBanner} alt="Master Samurai Tech Certification Badge" />
                            {/* <Box className="footer-headoffice">Head Office</Box> */}
                            <Grid container>
                                <Grid xsOffset={1} mdOffset={1} xs={2}><LocationPinIcon></LocationPinIcon></Grid>
                                <Grid className={'footer-text'} xs={5}>Silver City, NM</Grid>
                            </Grid>
                            <Grid container>
                                <Grid xsOffset={1} mdOffset={1} xs={2}><RedCallIcon></RedCallIcon></Grid>
                                <Grid className={'footer-text'} xs={5}>575-284-9771</Grid>
                            </Grid>
                            <Grid container>
                                <Grid xsOffset={1} mdOffset={1} xs={2}><RedMailIcon></RedMailIcon></Grid>
                                <Grid className={'footer-text'} xs={5}>armstrong.certified@gmail.com</Grid>
                            </Grid>
                            <Grid container>
                                <Grid xsOffset={1} mdOffset={1} xs={2}><RedFacebookIcon></RedFacebookIcon></Grid>
                                <Grid className={'footer-text'} xs={5}>@Armstrong.Certified</Grid>
                            </Grid>
                            <Grid container>
                                &nbsp;
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid xs={0} xl={2}>
                        <Stack className="footer-box" spacing={0}>
                            &nbsp;
                        </Stack>
                    </Grid>
                    <Grid xs={5} xsOffset={1} md={3} mdOffset={0} lg={2} lgOffset={0}>
                        <Stack className="footer-box" spacing={0} textAlign={'center'}>
                            <Box marginTop={'40px'}><a href="https://www.bbb.org/us/nm/silver-city/profile/appliance-repair/armstrong-certified-appliance-repair-llc-0806-99180087/#sealclick" target="_blank" rel="noreferrer"><img border={0} src="https://seal-newmexicoandsouthwestcolorado.bbb.org/seals/darkgray-seal-81-171-bbb-99180087.png" alt="Armstrong Certified Appliance Repair, LLC BBB Business Review" /></a></Box>
                        </Stack>
                    </Grid>
                    <Grid xs={5} md={3} lg={2}>
                        <Stack className="footer-box" spacing={0} textAlign={'center'}>
                            <Box marginTop={'40px'}><a href="https://mastersamuraitech.com/" target="_blank" rel="noreferrer"><img border={0} src={samuraiImage} alt="Master Samurai Tech Certification Badge" /></a></Box>
                        </Stack>
                    </Grid>

                    {/* <Grid xs={3} xl={1}>
                        <Stack spacing={0} >
                            <Box className="footer-title-text">Main Pages</Box>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>Home</Grid>
                            </Grid>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>About</Grid>
                            </Grid>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>Services</Grid>
                            </Grid>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>Appliance Parts</Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid xs={3} xl={2}>
                        <Stack spacing={0} >
                            <Box className="footer-title-text">Popular Services</Box>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>Washers</Grid>
                            </Grid>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>Dryers</Grid>
                            </Grid>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>Refrigerators</Grid>
                            </Grid>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>Dishwashers</Grid>
                            </Grid>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>Microwaves</Grid>
                            </Grid>
                            <Grid container>
                                <Grid className={'footer-info-text '} xs={5}>Ovens and Ranges</Grid>
                            </Grid>
                        </Stack>
                    </Grid> */}

                    <Grid container xs={12} className="footer-copyright" textAlign={'right'}>
                        <Grid xs={12} className={'footer-copyrightback'}>Copyright <CopyIcon></CopyIcon> Armstrong Certified Appliance Repair 2022&nbsp;&nbsp;&nbsp;</Grid>
                    </Grid>
                </Grid>

                <Modal
                    isOpen={this.state.showModal}
                    className="modal"
                    onRequestClose={this.handleCloseModal}
                >
                    Contact Request Sent!
                    <CloseButton variant="contained" onClick={this.handleCloseModal}>Close</CloseButton>
                </Modal>


                <Modal
                    isOpen={this.state.showErrorModal}
                    className="modal"
                    onRequestClose={this.handleCloseErrorModal}
                >
                    Something Went Wrong <br></br> Please try again later or call us at 575-284-9771
                    <CloseButton variant="contained" onClick={this.handleCloseErrorModal}>Close</CloseButton>
                </Modal>

            </Grid >


        );
    }
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);
